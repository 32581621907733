export enum QueryParamNames {
  CUSTOMER_ID = 'customerId',
  VEHICLE_ID = 'vehicleId',
  APPT_ID = 'appointmentId',
  REPAIR_ORDER_ID = 'repairOrderId',
  IS_REPAIR_ORDER = 'isRepairOrder',
  KEYWORD = 'keyword',
  PARTS_MATRIX = 'partsMatrix',
  PART_OVERRIDE = 'partOverride',
  USER_EMAIL_OR_USERNAME = 'userEmailUsername',
  USER_NAME = 'userName',
  USER_ID = 'userId',
  SERVICE_ID = 'serviceId',
  PART_TYPE_ID = 'partTypeId',
  TAX_EXEMPT = 'taxExempt',
  VEHICLE_YEAR = 'vehicleYear',
  VEHICLE_MAKE = 'vehicleMake',
  VEHICLE_MODEL = 'vehicleModel',
  INTAKE_REQUEST_ID = 'intakeRequestId'
}
